import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { Identifier } from 'react-admin'
import { axiosAuthInstance } from '../axios'

export const refundTZOrder = async (id: Identifier, notify: (msg?: string, typeObj?: object) => void) => {
  try {
    await axiosAuthInstance({
      method: 'post',
      url: `${API_AUTH_BASE_URL}/dashboard/ticket-orders/refund/${id}`,
    })
    notify('Ticket Order Refunded Successfully', { type: 'success' })
  } catch (error: any) {
    notify(`Ticket Order Refund Failed: ${error.response.data.message}`, { type: 'error' })
    throw new Error((error as Error).message)
  }
}
