import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { axiosAuthInstance } from '../axios'

export const refundDzBulkOrder = async (file: File, notify: (msg?: string, typeObj?: object) => void) => {
  const formdata = new FormData()
  formdata.append('file', file, 'file')

  try {
    await axiosAuthInstance({
      method: 'post',
      url: `${API_AUTH_BASE_URL}/dashboard/orders/bulk-refund`,
      data: formdata,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      if (res.data == 0) {
        notify(`No Order Refunded, Please check the orders eligibility for refund or check your wallet balance`, {
          type: 'error',
        })
      } else {
        notify(`${res.data}Orders Refunded Successfully`, { type: 'success' })
      }
    })
  } catch (error: any) {
    notify(`Orders Refund Failed: ${error.response.data.message}`, { type: 'error' })
    throw new Error((error as Error).message)
  }
}
