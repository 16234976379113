import { FormDataInput } from '@/modules/Variants/VariantsMultiUpload'
import { axiosAuthInstance } from '@/services/api/axios'

export const apiMultiVariantUpload = async (items: FormDataInput['items']) => {
  const formData = new FormData()

  // Transform data
  items?.forEach((item, index) => {
    if (item.attachments?.rawFile) {
      formData.append('files', item.attachments.rawFile)
      formData.append(`variantIds[${index}]`, String(item.variantId))
    }
  })

  // Post the FormData object to the endpoint
  const response = await axiosAuthInstance.post('/dashboard/vouchers/multiple', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  return response.data
}
