import {
  List,
  Datagrid,
  TextField,
  ChipField,
  DateField,
  BooleanField,
  BulkExportButton,
  ReferenceArrayField,
  SingleFieldList,
  BulkDeleteButton,
  TopToolbar,
  ExportButton,
  CreateButton,
  WithRecord,
  WrapperField,
  SearchInput,
} from 'react-admin'
import { EAdmin } from '@/modules/Admins/types'
import { tokens } from '@/Layout/theme'
import { EBrand } from '@/modules/Brands/types'
import Chip from '@mui/material/Chip'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
const AdminsBulkActionButtons = () => {
  return (
    <>
      <BulkExportButton />
      <Can I="deleteAdmin" an="admins">
        <BulkDeleteButton />
      </Can>
    </>
  )
}

const AdminsOrdersActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
      <Can I="createAdmin" an="admins">
        <CreateButton />
      </Can>
    </TopToolbar>
  )
}

const adminsFilters = [
  <SearchInput source={EAdmin.UsernameLike} alwaysOn key={1} placeholder="Search (User name)" />,
  <SearchInput source="ids" alwaysOn key={1} placeholder="Search (id)" />,
]

export const AdminsList = () => {
  const colors = tokens()

  return (
    <Can I="findAll" an="admins">
      <List actions={<AdminsOrdersActions />} filters={ability.can('findById', 'admins') ? adminsFilters : []}>
        <Datagrid
          rowClick={ability.can('updateAdmin', 'admins') ? 'edit' : false}
          bulkActionButtons={<AdminsBulkActionButtons />}
        >
          <TextField source={EAdmin.Id} sortable={false} />
          <TextField source={EAdmin.Username} sortable={false} label="User Name" />
          <BooleanField source={EAdmin.IsActive} sortable={false} />
          <TextField source={EAdmin.MerchantId} sortable={false} label="Merchant Id" />
          <ChipField
            sx={{
              backgroundColor: colors.accent.pink,
              color: colors.neutral[0],
            }}
            source={EAdmin.Role}
            sortable={false}
          />
          <ReferenceArrayField source={EAdmin.BrandIds} reference="brands" sortable={false}>
            <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px' }}>
              <TextField source={EBrand.EnName} />
            </SingleFieldList>
          </ReferenceArrayField>
          <DateField source={EAdmin.CreatedAt} sortable={false} />
          <DateField source={EAdmin.UpdatedAt} sortable={false} />

          <WrapperField label="Is deleted">
            <WithRecord
              render={(record) => (
                <Chip
                  sx={{
                    width: '90px',
                    color: record.deleted ? 'red' : 'blue',
                    textTransform: 'capitalize',
                  }}
                  label={record.deleted ? 'Deleted' : 'Not deleted'}
                />
              )}
            />
          </WrapperField>
        </Datagrid>
      </List>
    </Can>
  )
}
