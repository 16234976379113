import { SimpleForm, FileInput, FileField, useNotify, required } from 'react-admin'
import { postVoucherUpload } from '@/services/api/methods/postVoucherUpload'
import { postImageBulkUpload } from '@/services/api/methods/postImageBulkUpload'

export const VariantbulkImage = ({ handleClose }: { handleClose: () => void }) => {
  const notify = useNotify()
  const handleSubmit = (values: {
    attachments?: {
      rawFile: File
    }
  }) => {
    const { rawFile } = values.attachments || {}
    if (rawFile) {
      postImageBulkUpload(rawFile, notify).then(() => setTimeout(() => handleClose(), 200))
    }
  }

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <h5>
        Should be a text/csv file with <i>variant_id</i>, <i>image_url</i> columns.
        <p>Max 30 MiB</p>
      </h5>
      <FileInput
        source="attachments"
        accept="text/csv"
        validate={required()}
        sx={{ '& .RaFileInput-dropZone': { border: '1px dashed black' } }}
      >
        <FileField source="src" title="title" download={false} />
      </FileInput>
    </SimpleForm>
  )
}
