import { EMatch } from '../Matches/types'

export enum ETicketOrder {
  Id = 'id',
  OrderNumber = 'orderNumber',
  TotalPrice = 'totalPrice',
  UserId = 'userId',
  Status = 'status',
  TransactionId = 'transactionId',
  Match = 'match',
  User = 'user',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MinTotalPrice = 'minTotalPrice',
  MaxTotalPrice = 'maxTotalPrice',
  MatchId = 'matchId',
  Tickets = 'tickets',
  Refundable = 'refundable'
}

export type EUser = {
  name: string
  id: string
  phone: string
  ordersCount: number
  isBlocked: boolean
  createdAt: Date
}

export type TTicketOrder = {
  [ETicketOrder.Id]: number
  [ETicketOrder.OrderNumber]: string
  [ETicketOrder.TotalPrice]: number
  [ETicketOrder.UserId]: string
  [ETicketOrder.Status]: string | object
  [ETicketOrder.TransactionId]: string
  [ETicketOrder.Match]: EMatch
  [ETicketOrder.User]: EUser
  [ETicketOrder.CreatedAt]: Date
  [ETicketOrder.UpdatedAt]: Date
  [ETicketOrder.Refundable]: boolean
}
