import { createContext, useEffect } from 'react'
import { createContextualCan } from '@casl/react'
import { axiosAuthInstance } from '@/services/api/axios'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { ability, updateAbility } from './ability'
import { AppAbility } from '@/services/casl/defineAbility'

export interface Permission {
  subject: string
  action: string
}

interface AbilityProviderProps {
  children: React.ReactNode
}

export const AbilityContext = createContext<AppAbility | undefined>(undefined)
export const Can = createContextualCan(AbilityContext.Consumer as any)

export const AbilityProvider = ({ children }: AbilityProviderProps) => {
  const adminId = localStorage.getItem('ADMIN_ID')

  const fetchUserPermissions = async (adminId: string) => {
    try {
      const response = await axiosAuthInstance.get<{ userPermissions: Permission[] }>(
        `${API_AUTH_BASE_URL}/dashboard/admins/${adminId}/permissions`,
      )
      updateAbility(response.data.userPermissions)
    } catch (err) {
      console.log('Failed to fetch user permissions')
    }
  }

  useEffect(() => {
    fetchUserPermissions(adminId as string)
  }, [])

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}
