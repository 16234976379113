import {
  List,
  Datagrid,
  TextField,
  NumberField,
  WrapperField,
  DateField,
  ChipField,
  required,
  SelectInput,
  BulkExportButton,
  TopToolbar,
  FilterButton,
  TextInput,
  NumberInput,
  ReferenceInput,
  WithRecord,
  BooleanField,
  Confirm,
  Button,
} from 'react-admin'
import { tokens } from '@/Layout/theme'
import { EOrder, EStatus, EConsumer } from '@/modules/Orders/types'
import { LabelField } from '@/components/LabelField'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { ImportCSVOrderIdsModal } from '@/components/ImportCSVOrderIdsModal'
import { useState } from 'react'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import FileUploadIcon from '@mui/icons-material/UploadFile'

export const choices = [
  {
    id: 1,
    name: EStatus.SUCCESS,
  },
  {
    id: 2,
    name: EStatus.PENDING,
  },
  {
    id: 3,
    name: EStatus.CANCELLED,
  },
  {
    id: 4,
    name: EStatus.FAILURE,
  },
]
const ordersFilters = [
  <NumberInput source={EOrder.Id} alwaysOn key={1} label="Search (id)" />,
  <TextInput source={EOrder.UserPhoneNumberLike} alwaysOn key={2} label="Search (User phone)" />,
  <NumberInput source={EOrder.TransactionIdLike} alwaysOn key={3} label="Search (Transaction id)" />,
  <TextInput source={EOrder.MerchantUserId} key={4} label="Search (Merchant id)" />,
  <SelectInput
    label="Status Filter"
    source={EOrder.Status}
    defaultValue={EStatus.SUCCESS}
    key={EStatus.SUCCESS}
    choices={choices}
    validate={required()}
    optionValue="name"
  />,
  <TextInput source={EOrder.UserIdLike} key={5} label="User Id" />,

  <ReferenceInput source={EOrder.BrandId} reference={'brands'} perPage={1000} key={6}>
    <SelectInput optionText="enName" />
  </ReferenceInput>,

  <ReferenceInput source={EOrder.ProductId} reference={'products'} perPage={1000} key={7}>
    <SelectInput optionText="enName" />
  </ReferenceInput>,

  <ReferenceInput source={EOrder.VariantId} reference={'variants'} perPage={1000} key={8}>
    <SelectInput optionText="value[0][enValue]" />
  </ReferenceInput>,
]

const OrdersBulkActionButtons = () => {
  return (
    <>
      <BulkExportButton />
    </>
  )
}

export const OrdersList = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const colors = tokens()
  const handleCloseModal = () => {
    setLoading(false)
    setOpen(false)
  }

  const handleConfirm = () => {
    //setLoading(true)
    setOpen(false)
  }

  const handleLoadingg = () => {
    setLoading(true)
  }

  const PostOrdersActions = () => (
    <TopToolbar>
      <Can I="getOrder" an="orders">
        <FilterButton />
      </Can>
      <Button onClick={() => setOpen(true)} label="Import order_ids .csv">
        <FileUploadIcon />
      </Button>
    </TopToolbar>
  )
  return (
    <Can I="getAllOrders" an="orders">
      <List
        filters={ability.can('getOrder', 'orders') ? ordersFilters : []}
        sort={{ field: EOrder.CreatedAt, order: 'DESC' }}
        actions={<PostOrdersActions />}
      >
        <Confirm
          loading={loading}
          isOpen={open}
          title="Upload CSV of order_id for bulk refund"
          content={<ImportCSVOrderIdsModal handleClose={handleCloseModal} handleLoading={handleLoadingg}  />}
          cancel=""
          confirm="Close"
          ConfirmIcon={() => <ErrorOutlinedIcon />}
          CancelIcon={() => null}
          onConfirm={handleConfirm}
          onClose={handleCloseModal}
        />
        <Datagrid
          rowClick={ability.can('getOrder', 'orders') ? 'show' : false}
          bulkActionButtons={<OrdersBulkActionButtons />}
        >
          <DateField source={EOrder.CreatedAt} showTime />

          <NumberField source={EOrder.Id} label="ID" textAlign="left" sortable={false} />

          <TextField source={EOrder.TotalPrice} sortable={false} />

          <TextField source={EOrder.TransactionId} sortable={false} label="Transaction Id" />

          <LabelField source={EOrder.Status} label="Status" sortable={false} />

          <WithRecord
            label="Payment Method"
            render={(record) => {
              return (
                <>
                  <TextField source={EOrder.PaymentMethod} />{' '}
                  <span> - {record['merchant'] ? record['merchant']['name'] : ''}</span>
                </>
              )
            }}
          />
          <BooleanField source={EOrder.Refundable} sortable={false} />
        </Datagrid>
      </List>
    </Can>
  )
}
