import { useState } from 'react'
import { axiosAuthInstance } from '@/services/api/axios'
import { Button, Identifier, useNotify, useRefresh, useUnselectAll, Confirm } from 'react-admin'

export function BulkStatusActionButton({
  resource,
  selectedIds,
  url,
  actionKey,
  options,
}: {
  resource: string | undefined
  selectedIds: Identifier[] | undefined
  url: string
  actionKey: string // Dynamic property name for the action
  options: { value: boolean; label: string }[] // Array of options
}) {
  const [open, setOpen] = useState(false) // State to manage dialog visibility
  const [selectedOption, setSelectedOption] = useState<{ value: boolean; label: string } | null>(null) // Store the selected option
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll(resource as string)

  const handleConfirm = async () => {
    if (!selectedOption) return

    try {
      // Dynamically construct the request body
      const requestBody = {
        ids: selectedIds,
        [actionKey]: selectedOption.value, // Use dynamic key-value pair
      }

      const response = await axiosAuthInstance.put(url, requestBody)

      if (response.status) {
        notify(`${selectedOption.label} status applied successfully`, { type: 'info' })
        refresh()
        unselectAll()
      } else {
        throw new Error(`Failed to update status: ${response.statusText}`)
      }
    } catch (error: any) {
      notify(`Error: ${error?.message}`, { type: 'warning' })
    } finally {
      setOpen(false) // Close the dialog after action
    }
  }

  const handleClick = (option: { value: boolean; label: string }) => {
    setSelectedOption(option)
    setOpen(true) // Open the confirmation dialog
  }

  const handleDialogClose = () => {
    setOpen(false) // Close the dialog without taking action
  }

  return (
    <>
      {options.map((option) => (
        <Button key={option.label} label={option.label} onClick={() => handleClick(option)} />
      ))}
      <Confirm
        isOpen={open}
        title="Confirm Action"
        content={`Are you sure you want to set the status to "${selectedOption?.label}" for the selected records?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
