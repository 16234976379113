import { EConcert } from '@/modules/Concerts/types'
import { ESortOrder } from '@/types/data'
import {
  WrapperField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  SearchInput,
  DateTimeInput,
  ImageField,
  WithRecord,
  TopToolbar,
  CreateButton,
  ExportButton,
  BulkDeleteButton,
  Button,
  useNotify,
  Confirm,
  useRefresh,
} from 'react-admin'
import { Box } from '@mui/material'
import { EStadium } from '@/modules/Stadiums/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { getEventCancellationSummary, proceedEntireEventRefund } from '@/services/api/methods/eventCancellation'
import { useState } from 'react'

interface NotPublishedStyleProps {
  backgroundColor: string
  color: string
  borderRadius: string
  padding: string
  width: string
  display: string
  justifyContent: string
}

const notPublishedStyle: NotPublishedStyleProps = {
  backgroundColor: '#ad2f44',
  color: 'white',
  borderRadius: '30px',
  padding: '5px',
  width: '6rem',
  display: 'flex',
  justifyContent: 'center',
}

const concertStyle = {
  backgroundColor: '#227d4f',
  color: 'white',
  borderRadius: '30px',
  padding: '5px',
  width: '6rem',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
}

const workshopStyle = {
  backgroundColor: '#4287f5',
  color: 'white',
  borderRadius: '30px',
  padding: '5px',
  width: '6rem',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
}
const notPublishedStyleCSS: React.CSSProperties = notPublishedStyle

const concertsList = [
  <SearchInput source={EConcert.ArTitleLike} alwaysOn key={1} placeholder="Search (Arabic title)" />,
  <SearchInput source={EConcert.ArSubtitleLike} alwaysOn key={2} placeholder="Search (Arabic subtitle)" />,
  <DateTimeInput source={EConcert.MinDateAndTime} key={6} label="Search (Min date)" />,
  <DateTimeInput source={EConcert.MaxDateAndTime} key={7} label="Search (Max date)" />,
]
interface OrderCancellationSummary {
  selectedEventId: number
  totalOrdersToBeRefunded: number
  totalRefundableAmount: number
}
export const ConcertsList = () => {
  const [orderCancellationSummary, setOrderCancellationSummary] = useState<OrderCancellationSummary | null>(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleDialogClose = () => setOpen(false)

  const handleCancellationInfo =
    ({ eventId }: any) =>
    async (event: React.MouseEvent<HTMLElement>) => {
      setLoading(true)
      event.preventDefault()
      event.stopPropagation()
      try {
        await getEventCancellationSummary(eventId, notify).then((res) => {
          setOrderCancellationSummary({
            selectedEventId: eventId,
            totalOrdersToBeRefunded: res.data.totalOrdersToBeRefunded,
            totalRefundableAmount: res.data.totalRefundableAmount,
          })

          setOpen(true)
        })
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

  const handleRefund = async () => {
    setLoading(true)
    if (!orderCancellationSummary) return
    try {
      await proceedEntireEventRefund(orderCancellationSummary?.selectedEventId, notify)
      refresh()
      handleDialogClose()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const ListActions = () => (
    <TopToolbar>
      <Can I="create" a="concerts">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )
  return (
    <Can I="findAll" a="concerts">
      <List
        filters={ability.can('findOne', 'concerts') ? concertsList : []}
        sort={{ field: EConcert.CreatedAt, order: ESortOrder.Desc }}
        actions={<ListActions />}
      >
        <Datagrid
          rowClick={ability.can('findOne', 'concerts') ? 'edit' : false}
          bulkActionButtons={
            <Can I="remove" a="concerts">
              <BulkDeleteButton />
            </Can>
          }
        >
          <NumberField source={EConcert.Id} textAlign="left" sortable />
          <TextField source={EConcert.EventKey} sortable={false} />
          <DateField source={EConcert.DateAndTime} showTime label={<span>Date and Time</span>} sortable={false} />
          <WithRecord
            label="Published At"
            render={(record) => {
              switch (record[EConcert.PublishedAt]) {
                case null: {
                  return <div style={notPublishedStyleCSS}>Not Published</div>
                }
                default: {
                  return <DateField source={EConcert.PublishedAt} showTime label={<span>Published At</span>} sortable />
                }
              }
            }}
          />
          <TextField source={EConcert.ArTitle} label="Title (ar)" sortable />
          <TextField source={EConcert.ArSubtitle} label="Subtitle (ar)" sortable />
          <TextField source={EConcert.ArAddress} label="Address (ar)" />
          <ImageField
            label="Image"
            source={`${EConcert.ImageLink}`}
            sx={{
              '& img': {
                maxWidth: '100px',
                maxHeight: 'auto',
                objectFit: 'cover !important',
                borderRadius: '10%',
              },
              display: 'flex',
              marginRight: '8px',
            }}
          />
          <WithRecord
            label="Concert Category"
            render={(record) => {
              return (
                <div style={record[EConcert.ConcertCategory] == 'WORKSHOP' ? workshopStyle : concertStyle}>
                  {record[EConcert.ConcertCategory]}
                </div>
              )
            }}
          />
          <WithRecord
            label="Event State"
            render={(record) => {
              return <div>{record[EConcert.State]}</div>
            }}
          />

          <WrapperField label="Stadium">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="10 20"
              justifyContent="space-between"
              width="200px"
              border="1px solid blue"
            >
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${EConcert.Stadium}.${EStadium.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${EConcert.Stadium}.${EStadium.KuName}`} sortable={false} />
              </Box>
            </Box>
          </WrapperField>
          <WithRecord
            render={(record) => (
              <>
                {record[EConcert.State] == 'CANCELLED' ? (
                  <div></div>
                ) : (
                  <Button onClick={handleCancellationInfo(record)} variant="outlined" disabled={loading}>
                    <h6
                      style={{
                        margin: '0',
                        fontSize: '12px',
                      }}
                    >
                      Cancel & Refund
                    </h6>
                  </Button>
                )}
              </>
            )}
          />
        </Datagrid>
      </List>
      {orderCancellationSummary && (
        <Confirm
          loading={loading}
          isOpen={open}
          title="Cancellation Summary"
          content={
            <>
              {orderCancellationSummary.totalOrdersToBeRefunded > 0 ? (
                <div>
                  <p>
                    Total Orders to Be Refunded: <b>{orderCancellationSummary.totalOrdersToBeRefunded}</b>
                  </p>
                  <p>
                    Total Refundable Amount: <b>{orderCancellationSummary.totalRefundableAmount}</b>
                  </p>
                </div>
              ) : (
                <div>
                  This event will be cancelled without any refundings because there are no ticket orders to be refunded
                </div>
              )}
            </>
          }
          onConfirm={async () => {
            await handleRefund()
          }}
          onClose={handleDialogClose}
        />
      )}
    </Can>
  )
}
