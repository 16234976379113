import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { Identifier } from 'react-admin'
import { axiosAuthInstance } from '../axios'

export const getEventCancellationSummary = async (id: Identifier, notify: (msg?: string, typeObj?: object) => void) => {
  try {
    return await axiosAuthInstance({
      method: 'get',
      url: `${API_AUTH_BASE_URL}/dashboard/events/getEventCancellationSummary/${id}`,
    })
  } catch (error: any) {
    notify(`Error: Event Cancellation Summary: ${error.response.data.message}`, { type: 'error' })
    throw new Error((error as Error).message)
  }
}

export const proceedEntireEventRefund = async (id: Identifier, notify: (msg?: string, typeObj?: object) => void) => {
  try {
    await axiosAuthInstance({
      method: 'post',
      url: `${API_AUTH_BASE_URL}/dashboard/events/proceedEventRefund/${id}`,
    })
    notify('Event Cancelled Successfully', { type: 'success' })
  } catch (error: any) {
    notify(`Error: Event Cancellation Summary: ${error.response.data.message}`, { type: 'error' })
    throw new Error((error as Error).message)
  }
}
